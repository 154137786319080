<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Katalog Produktów Promowanych</b> w tym miejscu znajdziesz wszystkie
        produkty objęte Programem Lojalnościowym
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Katalog Produktów Promowanych'">
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col>
                    <b-form-checkbox @change="searchSkus" v-model="checkedGroups" value="1" name="check-button" switch size="lg" button-variant="success">
                        <b-badge variant="success">Grupa 1 = 240</b-badge>
                    </b-form-checkbox>
                    <b-form-checkbox @change="searchSkus" v-model="checkedGroups" value="2" name="check-button" switch size="lg" button-variant="info">
                        <b-badge variant="info">Grupa 2 = 160</b-badge>
                    </b-form-checkbox>
                    <b-form-checkbox @change="searchSkus" v-model="checkedGroups" value="3" name="check-button" switch size="lg" button-variant="warning">
                        <b-badge variant="warning">Grupa 3 = 80</b-badge>
                    </b-form-checkbox>
                    <b-form-checkbox @change="searchSkus" v-model="checkedGroups" value="4" name="check-button" switch size="lg" button-variant="secondary">
                        <b-badge variant="secondary">Grupa 4 = 40</b-badge>
                    </b-form-checkbox>
                    <b-form-checkbox @change="searchSkus" v-model="checkedGroups" value="5" name="check-button" switch size="lg" button-variant="primary">
                        <b-badge variant="primary">Grupa 5 = 8</b-badge>
                    </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj po nazwie"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          :disabled="!filter"
                          @click="searchSkus"
                          ><b-icon icon="search"></b-icon
                        ></b-button>
                      </b-input-group-append>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="clearFilter"
                          >wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12" md="12" class="my-1">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="getPromotionSkus"
                    align="right"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>

              <b-table
                ref="promotionskustable"
                responsive
                striped
                hover
                :items="promotionSkus.items"
                :fields="promotionSkus.fields"
                :tbody-tr-class="rowClass"
                v-if="state === 'loaded'"
              >
              </b-table>

              <div
                class="d-flex justify-content-center mb-3"
                v-if="state === 'loading'"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  name: "Punctation",

  data() {
    return {
      totalRows: null,
      currentPage: null,
      perPage: null,
      state: "loading",
      filter: "",
      checkedGroups: ["1", "2", "3", "4", "5"],
      promotionSkus: {
        fields: [
          {
            key: "code",
            label: "Kod Produktu",
            sortable: true
          },
          {
            key: "name",
            label: "Nazwa Produktu",
            sortable: true
          },
          {
            key: "um",
            label: "jm",
            sortable: true
          },
          {
            key: "skuGroupName",
            label: "Grupa Towarowa",
            sortable: true
          },
          {
            key: "pjgroup",
            label: "Grupa",
            sortable: true
          },
          {
            key: "points",
            label: "Punktacja",
            sortable: true
          }
        ],
        items: []
      }
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getPromotionSkus(page = 1) {
      this.state = "loading";

      axios
        .get("promotion-skus-pagable", {
          params: {
            page: page,
            filter: this.filter,
            groups: this.checkedGroups
          }
        })
        .then(response => {
          this.promotionSkus.items = response.data.content;
          this.totalRows = response.data.totalElements;
          this.perPage = response.data.size;
          this.currentPage = page;

          this.state = "loaded";
        });
    },

    searchSkus() {
      this.getPromotionSkus();
    },

    clearFilter() {
      this.filter = "";
      this.getPromotionSkus();
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.pjgroup === "1") return "table-success";
      if (item.pjgroup === "2") return "table-info";
      if (item.pjgroup === "3") return "table-warning";
      if (item.pjgroup === "4") return "table-secondary";
      if (item.pjgroup === "5") return "table-primary";
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Katalog Produktów Promowanych" },
      { title: "" }
    ]);

    this.getPromotionSkus();
  }
};
</script>
